var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("span", {
    staticClass: "i-layout-header-trigger",
    class: {
      "i-layout-header-trigger-min": _vm.showSiderCollapse
    },
    on: {
      click: _vm.handleReload
    }
  }, [_c("Icon", {
    attrs: {
      custom: "i-icon i-icon-refresh"
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };